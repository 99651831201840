import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Button } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function IconList(props) {
  const classes = useStyles();

  return props.list.map(item => (
    <List dense key={item.id} className={classes.root}>
      <ListItem color="primary">
        <ListItemAvatar>
          <Button target="-" href="facebook">
            <Avatar alt="Remy Sharp" src={item.icon} />
          </Button>
        </ListItemAvatar>
        <ListItemText primary={item.name} secondary={item.version} />
      </ListItem>
    </List>
  ));
}
