import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Table from "../../components/Table/Table.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { NavLink } from "react-router-dom";
import API from "../../constants/url";
import axios from "axios";
import SimpleBackdrop from "../../components/Loader/Loader.js";
import ResponsiveDialog from "../../components/Dialogue/Dialogue.js";
import Axios from "axios";
import FullScreenDialog from "../../components/fulldialogue/fulldialogue.js";
const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0",
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF",
    },
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  styles,
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function TableList() {
  const [list, setList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [delet, setDelete] = React.useState(null);
  const [edit, setEdit] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [editopen, seteditOpen] = React.useState(false);
  React.useEffect(() => {
    setLoader(true);
    refresh();
  }, []);
  const monthDiff = (dateFrom, dateTo) => {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  };
  const refresh = () => {
    const url = API.heroku + "/apps/getall";
    axios
      .get(url)
      .then((res) => {
        console.log(res);
        setLoader(false);
        let date = new Date();
        let data = res.data.data;
        data.forEach((el) => {
          el.lastUpdate = el.lastUpdate.substring(0, 24);
          let lm = new Date(el.lastUpdate);
          if (monthDiff(lm, date) === 2) {
            el.style = { color: "orange" };
          } else if (monthDiff(lm, date) > 2) {
            el.style = { color: "red" };
          } else {
            el.style = { color: "green" };
          }
        });
        setList(data);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        return err;
      });
  };
  const classes = useStyles();
  const icon = <AddIcon />;

  const Delete = (e) => {
    console.log(e);
    setDelete(e);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const confirmDelete = () => {
    const url = API.heroku + "/apps/delete";
    setLoader(true);
    handleClose();

    Axios.post(url, delet)
      .then((res) => {
        setLoader(false);
        refresh();
      })
      .catch((err) => {
        setLoader(false);
        return err;
      });
  };
  const onEditClick = (e) => {
    seteditOpen(true);
    setEdit(e);
  };
  const oneditClose = () => {
    seteditOpen(false);
  };
  const onEditSubmit = (d1, d2) => {
    console.log(d1, d2);
  };
  const onTestClick = (e) => {
    console.log(e.tested);
    if (!e.tested) {
      e.tested = true;
    } else {
      e.tested = false;
    }
    const url = API.heroku + "/apps/tested";
    setLoader(true);
    Axios.put(url, e)
      .then((res) => {
        setLoader(false);
        refresh();
      })
      .catch((err) => {
        setLoader(false);
        return err;
      });
  };
  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>App List</h4>
            <p className={classes.cardCategoryWhite}>Applications Details</p>
            <NavLink to="/admin/user">
              <Fab aria-label="addnew" className={classes.fab} color="primary">
                {icon}
              </Fab>
            </NavLink>
          </CardHeader>
          <CardBody>
            {list ? (
              <Table
                tableHeaderColor="primary"
                DeleteClick={Delete}
                EditClick={onEditClick}
                tableHead={[
                  "App Name",
                  "Icon",
                  "Last Updated",
                  "Version",
                  "Installs",
                  "Status",
                  "Action",
                ]}
                tableData={list}
                TestClick={onTestClick}
              />
            ) : (
              ""
            )}
          </CardBody>
          {/* <CardFooter profile>
            <NavLink to="/admin/user">
              <Fab aria-label="addnew" className={classes.fab} color="primary">
                {icon}
              </Fab>
            </NavLink>
          </CardFooter> */}
        </Card>
      </GridItem>
      <SimpleBackdrop loader={loader}></SimpleBackdrop>
      <ResponsiveDialog
        open={open}
        content={"Are you sure you want to delete this item?"}
        handleClose={handleClose}
        handleConfirm={confirmDelete}
      ></ResponsiveDialog>
      <FullScreenDialog
        editOpen={editopen}
        oneditClose={oneditClose}
        state={edit}
        EditSubmit={onEditSubmit}
      ></FullScreenDialog>
    </GridContainer>
  );
}
