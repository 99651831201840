import React, { useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logo from "../../assets/img/logo.jpg";
import SimpleBackdrop from "../../components/Loader/Loader";
import Axios from "axios";
import API from "../../constants/url";
import { createHashHistory } from "history";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" target="-" href="http://riafysolutions.com/">
        Riafy Technology
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const hist = createHashHistory();
  useEffect(() => {
    const user = localStorage.getItem("currentUser");
    if (user) {
      hist.push("/admin/table");
    }
  }, []);
  const [state, setState] = React.useState({ email: "", password: "" });
  const [loader, setLoader] = React.useState(false);
  const onhandleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = { ...state };
    temp[name] = value;
    setState(temp);
  };
  const Submit = () => {
    setLoader(true);
    const url = API.heroku + "/auth/login";
    Axios.post(url, state)
      .then((res) => {
        setLoader(false);
        localStorage.setItem("currentUser", res.data.data);
        console.log(res);
        hist.push("/admin/table");
      })
      .catch((err) => {
        setLoader(false);
        alert("Authentication Failed");
      });
  };
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar} src={logo}></Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={onhandleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            onChange={onhandleChange}
            autoComplete="current-password"
          />

          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => Submit()}
            className={classes.submit}
          >
            Sign In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <div>
        <SimpleBackdrop loader={loader}></SimpleBackdrop>
      </div>
    </Container>
  );
}
