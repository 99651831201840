import React from "react";
import "./App.css";
import { createBrowserHistory } from "history";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
  withRouter
} from "react-router-dom";
import Admin from "./layouts/Admin.js";
import "./assets/css/material-dashboard-react.css?v=1.8.0";
// import "./assets";

function App() {
  const hist = createBrowserHistory();

  return (
    <Router history={hist}>
      <Switch>
        <Route path="/admin" component={withRouter(Admin)} />
        <Redirect from="/" to="/admin/login" />
      </Switch>
    </Router>
  );
}

export default App;
