import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import {
  Button,
  CircularProgress,
  IconButton,
  Avatar
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const classes = useStyles();
  const progress = {
    color: "#000",
    position: "absolute",
    marginTop: 4,
    marginLeft: -75
  };
  const {
    tableHead,
    tableData,
    tableHeaderColor,
    DeleteClick,
    TestClick,
    loading
  } = props;
  tableData.forEach(element => {});
  return (
    <div>
      <div className={classes.tableResponsive}>
        <Table className={classes.table}>
          {tableHead !== undefined ? (
            <TableHead className={classes[tableHeaderColor + "TableHeader"]}>
              <TableRow className={classes.tableHeadRow}>
                {tableHead.map((prop, key) => {
                  return (
                    <TableCell
                      align="center"
                      className={
                        classes.tableCell + " " + classes.tableHeadCell
                      }
                      key={key}
                    >
                      {prop}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
          ) : null}
          <TableBody>
            {tableData.map((prop, key) => {
              return (
                <TableRow key={prop.id} className={classes.tableBodyRow}>
                  <TableCell align="center" className={classes.tableCell}>
                    {prop.name}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <Button target="-" href={prop.url}>
                      <Avatar alt="Remy Sharp" src={prop.icon} />
                    </Button>
                  </TableCell>
                  <TableCell
                    align="center"
                    className={classes.tableCell}
                    style={prop.style}
                  >
                    {prop.lastUpdate}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {prop.version}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {prop.installs}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ backgroundColor: "#8e24aa" }}
                        disabled={props.loading}
                        onClick={() => TestClick(prop)}
                      >
                        {prop.tested ? "TESTED" : "NOT TESTED"}
                      </Button>
                      {props.loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                          style={progress}
                        />
                      )}
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => DeleteClick(prop)}
                    >
                      <DeleteIcon
                        fontSize="small"
                        style={{ color: "#8e24aa" }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  // tableData: PropTypes.arrayOf(PropTypes.string),
  deleteClick: PropTypes.func
};
