import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { NavLink } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../../App.css";
import API from "../../constants/url";
import axios from "axios";
import IconList from "../../components/List/list.js";
import SimpleBackdrop from "../../components/Loader/Loader";
const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const [list, setList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    refresh();
  }, []);
  const refresh = () => {
    const url = API.heroku + "/apps/getall";
    axios
      .get(url)
      .then(res => {
        console.log(res);
        setLoader(false);
        let data = [];
        let arr = res.data.data;
        let length = arr.length > 3 ? 3 : arr.length;
        for (let i = 0; i < length; i++) {
          data.push(arr[i]);
        }
        setList(data);
      })
      .catch(err => {
        setLoader(false);
        return err;
      });
  };
  const [state, setSate] = React.useState({
    url: ""
  });

  const stateChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let temp = { ...state };
    temp[name] = value;
    setSate(temp);
  };
  const Submit = () => {
    setLoader(true);
    const url = API.heroku + "/parse/create";
    let body = {
      url: state.url
    };
    axios
      .post(url, body)
      .then(res => {
        console.log(res);
        setTimeout(() => {
          setLoader(false);
          alert("Record has been successfully added!");
          refresh();
          let temp = { ...state };
          temp.url = "";
          setSate(temp);
        }, 5000);
      })
      .catch(err => {
        setLoader(false);
        return err;
      });
  };
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>Add new App Info</h4>
              <p className={classes.cardCategoryWhite}>
                Complete Application Info
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Application Url"
                    id="appname"
                    name="url"
                    value={state.name}
                    onstateChange={stateChange}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter>
              <Button color="primary" onSubmit={Submit}>
                SUBMIT
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card profile>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>App List</h4>
            </CardHeader>
            <CardBody profile>
              <div>
                <IconList list={list} />
              </div>
              <NavLink to="/admin/table">
                <Button color="primary" round>
                  View More
                </Button>
              </NavLink>
            </CardBody>
          </Card>
        </GridItem>
        <div>
          <SimpleBackdrop loader={loader}></SimpleBackdrop>
        </div>
      </GridContainer>
    </div>
  );
}
